import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';  
import { MatSelectModule} from '@angular/material/select';

import { ClickOutsideDirective } from './clickoutside.directive';
import { SpinnerDirective } from './spinner.directive';
import { GridStateDirective } from './gridState.directive';
import { PhoneMaskDirective } from './phone-number-mask.directive';
import { AlphanumericDirective } from './alphanumeric.directive';

@NgModule({
    exports: [
        CommonModule,
        ClickOutsideDirective,
        GridStateDirective,
        SpinnerDirective,
        PhoneMaskDirective,
        AlphanumericDirective
    ],
    imports: [
        CommonModule,
        NgbModule,
        MatSelectModule
    ],
    declarations: [
        ClickOutsideDirective,
        SpinnerDirective,
        GridStateDirective,
        PhoneMaskDirective,
        AlphanumericDirective
    ]
})
export class CommonDirectivesModule { }
