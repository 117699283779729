import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface MenuItem {
    id: string;
    icon: string;
    hidden: boolean;
    link?: string;
    children?: {
        id: string;
        link: string;
        hidden: boolean;
    }[];
}

const MENU_ITEMS: MenuItem[] = [
    {
        id: 'patron',
        icon: '/assets/icons/svgs/Patron.svg',
        hidden: false,
        children: [
            {
                id: 'patronDetail',
                link: '/pages/patron/detail',
                hidden: false
            },
            {
                id: 'patronCollectionUse',
                link: '/pages/patron/collection-use',
                hidden: false
            }
        ]
    },
    {
        id: 'community',
        icon: '/assets/icons/svgs/Community.svg',
        hidden: false,
        children: [
            {
                id: 'communityMap',
                link: '/pages/patronMap',
                hidden: false
            },
            {
                id: 'demographics',
                link: '/pages/demographics',
                hidden: false
            }
        ]
    },
    {
        id: 'collectionAnalyze',
        icon: '/assets/icons/svgs/CollectionAnalyze.svg',
        hidden: false,
        children: [
            {
                id: 'physicalCollectionStats',
                link: '/pages/collectionStats',
                hidden: false
            },
            {
                id: 'physicalCollectionCodes',
                link: '/pages/collectionCodes',
                hidden: false
            },
            {
                id: 'physicalCollectionByDewey',
                link: '/pages/collectionDewey',
                hidden: false
            },
            {
                id: 'physicalCollectionByBisac',
                link: '/pages/collectionBisac',
                hidden: false
            },
            {
                id: 'physicalCollectionByFindIt',
                link: '/pages/collectionFindIt',
                hidden: false
            },
            {
                id: 'physicalCollectionByLLC',
                link: '/pages/collectionLCC',
                hidden: false
            },
            {
                id: 'physicalCollectionByBranch',
                link: '/pages/collectionBranch',
                hidden: false
            },
            {
                id: 'physicalCollectionPurchaseHistory',
                link: '/pages/collectionHistory',
                hidden: false
            }
        ]
    },
    {
        id: 'collectionCirculation',
        icon: '/assets/icons/svgs/CollectionCirculation.svg',
        hidden: false,
        children: [
            {
                id: 'physicalCircStats',
                link: '/pages/circulationStats',
                hidden: false
            },
            {
                id: 'physicalCircTrends',
                link: '/pages/circulationTrend',
                hidden: false
            },
            {
                id: 'physicalCircDetail',
                link: '/pages/circulationDetail',
                hidden: false
            },
            {
                id: 'physicalCircDetailByLocation',
                link: '/pages/locationCirculation',
                hidden: false
            },
            {
                id: 'physicalElectronicCirculation',
                link: '/pages/em/peCircCompared',
                hidden: false
            },
            {
                id: 'electronicCost',
                link: '/pages/em/cost',
                hidden: false
            },
            {
                id: 'electronicVendorFormats',
                link: '/pages/em/electronicByVendor',
                hidden: false
            }
        ]
    },
    {
        id: 'collectionDiscover',
        icon: '/assets/icons/svgs/CollectionDiscover.svg',
        hidden: false,
        children: [
            {
                id: 'trendingTitles',
                link: '/pages/trendingTitles',
                hidden: false
            },
            {
                id: 'bookLists',
                link: '/pages/bestSellers',
                hidden: false
            },
            {
                id: 'forthcomingTitles',
                link: '/pages/forthcomingTitles',
                hidden: false
            },
            {
                id: 'childrensDEIBookFinder',
                link: '/pages/diversifiedBookFinder',
                hidden: false
            },
            {
                id: 'recommendedDEITitles',
                link: '/pages/deiBookLists',
                hidden: false
            },
            {
                id: 'collectionContributors',
                link: '/pages/collectionAuthors',
                hidden: false
            },
            {
                id: 'collectionSeries',
                link: '/pages/collectionSeries',
                hidden: false
            }
        ]
    },
    {
        id: 'collectionAcquire',
        icon: '/assets/icons/svgs/CollectionAcquire.svg',
        hidden: false,
        children: [
            {
                id: 'budgetSpending',
                link: '/pages/BudgetSpend',
                hidden: false
            },
            {
                id: 'titlesOrdered',
                link: '/pages/titlesOrdered',
                hidden: false
            },
            {
                id: 'openOrders',
                link: '/pages/openOrders',
                hidden: false
            },
            {
                id: 'invoices',
                link: '/pages/allInvoices',
                hidden: false
            }
        ]
    },
    {
        id: 'collectionMaintain',
        icon: '/assets/icons/svgs/CollectionMaintain.svg',
        hidden: false,
        children: [
            {
                id: 'reportBuilder',
                link: '/pages/ReportBuilder',
                hidden: false
            },
            {
                id: 'balance',
                link: '/pages/balance',
                hidden: false
            },
            {
                id: 'balanceReport',
                link: '/pages/balanceReport',
                hidden: false
            },
            {
                id: 'inventory',
                link: '/pages/inventory',
                hidden: false
            },
            {
                id: 'shelvingAllocation',
                link: '/pages/shelving-allocation',
                hidden: false
            }
        ]
    },
    {
        id: 'diversity',
        icon: '/assets/icons/svgs/Diversity.svg',
        hidden: false,
        children: [
            {
                id: 'deiCollectionMakeup',
                link: '/pages/collectionDistributionByDEICategory',
                hidden: false
            },
            {
                id: 'deiCollectionPerformance',
                link: '/pages/collectionByDEICategory',
                hidden: false
            },
            {
                id: 'deiPurchaseHistory',
                link: '/pages/deiCollectionHistory',
                hidden: false
            },
            {
                id: 'recommendedDEITitles',
                link: '/pages/deiBookLists',
                hidden: false
            },
            {
                id: 'childrensDEIBookFinder',
                link: '/pages/diversifiedBookFinder',
                hidden: false
            }
        ]
    },
    {
        id: 'metrics',
        icon: '/assets/icons/svgs/Metrics.svg',
        hidden: false,
        children: [
            {
                id: 'metricsAnalysis',
                link: '/pages/metricsView',
                hidden: false
            },
            {
                id: 'metricsDashboard',
                link: '/pages/metrics-dashboard',
                hidden: false
            },
            {
                id: 'enterMetrics',
                link: '/pages/metricsEdit',
                hidden: false
            },
            {
                id: 'footTraffic',
                link: '/pages/footTraffic',
                hidden: false
            }
        ]
    },
    {
        id: 'actionPlan',
        icon: '/assets/icons/svgs/ActionPlan.svg',
        hidden: false,
        link: '/pages/cmPlan'
    },
    {
        id: 'customReports',
        icon: '/assets/icons/svgs/CustomReports.svg',
        hidden: false,
        link: '/pages/customReports'
    }
];

@Injectable({ providedIn: 'root' })
export class MenuService {
    favoritesToDisplay: any[] = [];
    currentFavorite: boolean;

    menu$ = new BehaviorSubject<MenuItem[]>([]);

    constructor() {
        this.refreshMenu();
    }

    refreshMenu(): void {
        this.menu$.next(JSON.parse(JSON.stringify(MENU_ITEMS)));
    }

    setMenuVisibility(id: string, show: boolean): void {
        const menu = this.menu$.value;
        menu.forEach((menuItem) => {
            if (menuItem.id === id) {
                menuItem.hidden = !show;
            }
            if (menuItem.children?.length > 0) {
                menuItem.children.forEach((childItem) => {
                    if (childItem.id === id) {
                        childItem.hidden = !show;
                    }
                });
            }
        });

        this.menu$.next(menu);
    }

    getMenu(): MenuItem[] {
        // return this.menu;
        return this.menu$.value;
    }
}
