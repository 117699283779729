import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReportService } from './report.service';

interface FeatureFlag {
    name: string;
    enabled: boolean;
    libraries: number[];
}

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
    private loading = false;
    private featureFlags$ = new BehaviorSubject<FeatureFlag[]>([]);

    constructor(
        private reportService: ReportService,
        private http: HttpClient
    ) {}

    clearFeatureFlags(): void {
        this.featureFlags$.next([]);
    }

    loadFeatureFlags(): void {
        this.loading = true;
        this.http.get<{success: boolean; data: FeatureFlag[]}>('/api/feature-flags')
            .subscribe(({data}) => {
                this.loading = false;
                this.featureFlags$.next(data);
            });
    }

    hasFeature(flagName: string): Observable<boolean> {
        if (this.featureFlags$.value.length === 0 && !this.loading) {
            this.loadFeatureFlags();
        }

        return combineLatest([
            this.featureFlags$.pipe(
                map((flags) => flags.find(flag => flag.name === flagName))
            ),
            this.reportService.monitorChange
        ]).pipe(
            map(([flag, {currentLibrary}]) => {
                if (!flag) return false;
                if (!flag.enabled) return false;

                if (flag.libraries.length > 0) {
                    return flag.libraries.includes(currentLibrary);
                }

                return flag.enabled;
            })
        );
    }
}
